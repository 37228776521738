.modal.nav-left .modal-dialog {
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0) !important;
    -ms-transform: translate3d(0%, 0, 0) !important;
    -o-transform: translate3d(0%, 0, 0) !important;
    transform: translate3d(0%, 0, 0) !important;
}

.modal.nav-left .modal-content {
    overflow-y: auto;
}

.modal.nav-left.fade .modal-dialog {
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
    margin: 0;
}

.modal.nav-left.fade.show .modal-dialog {
    left: 0;
    margin: 0;
}

/* ----- MODAL STYLE ----- */
.modal.nav-left .modal-content {
    border-radius: 0;
    border: none;
}

.modal.nav-left .modal-header {
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
}



/* stroke */
.navbar-nav .nav-link {
    position: relative;
}

.navbar-nav .nav-link:not(.mobile-nav-link):after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    content: '.';
    color: transparent;
    background-color: transparent;
    height: 2px;
}

.navbar-nav .nav-link:not(.mobile-nav-link):hover:after,
.navbar-nav .nav-link:not(.mobile-nav-link).active:after {
    background-color: #ff922a;
}

.navbar-nav .nav-link:not(.mobile-nav-link).active {
    color: #ff922a !important;
    font-weight: 700;
}

.navbar-nav .nav-link,
.navbar-nav .nav-link:after,
.navbar-nav .nav-link:before {
    transition: all .5s;
}

.toggle-menu {
    padding: .5rem !important;
}


.toggle-menu:hover {
    background-color: transparent !important;
    color: #ff9f43 !important;
}


.navbar-nav .nav-link,
.nav-link.mobile-nav-link {
    font-weight: 400;
    color: #6e6b7b !important;
}

.navbar-nav .nav-link.mobile-nav-link.active {
    background: linear-gradient(118deg, #ff9f43, rgba(255, 159, 67, .7)) !important;
    box-shadow: 0 0 10px 1px rgb(255 159 67 / 70%) !important;
    color: #fff !important;
    font-weight: 400 !important;
    border-radius: 4px !important;
    padding: 10px 15px !important;
}

.toggle-menu:hover {
    background-color: transparent !important;
    color: #ff9f43 !important;
}

@media (min-width: 992px) {
    .nav-shadow {
        box-shadow: none !important;
    }
}

.nav-shadow {
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    background-color: white !important;
}

.navbar-nav .nav-link.mobile-nav-link,
.navbar-nav .nav-item.mobile-nav-link {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}


// New Try

.mobile-nav-dropdown {
    display: flex !important;
    justify-content: space-between;
    transition: all .2s ease-out;
    border-radius: 4px !important;
    padding: 0.75rem 1rem;
    align-items: center;
    text-align: start;

    &::after {
        content: "";
        width: 1rem;
        height: 1rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
        transform: rotate(0deg);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 1rem;

        display: inline-block;
        position: absolute;
        transition: all .2s ease-out;
        right: 20px;
    }
}

.mobile-nav-dropdown.mobile-nav-dropdown.toggled {
    background-color: #f5f5f5 !important;
    border: 0 !important;
    color: #6e6b7b !important;
}

.mobile-nav-dropdown.toggled::after {
    transform: rotate(90deg);
    transition: all .2s ease-out;
}

.menus {
    display: flex;
    list-style: none;
}

.menu-items {
    position: relative;
}

.menu-items .menu-item-link {
    font-size: 14px !important;
}

.arrow {
    display:inline-block;
    vertical-align:middle;
    margin-left: .5rem;

    &::after {
        content: "";
        display: inline-block;
        margin-left: 0.02em;
        width: .75rem;
        height: .75rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
        transform: rotate(90deg);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: .75rem;
    }
}

.arrow-right {
    &::after {
        transform: rotate(0deg);
    }
}

.navbar-dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 1050;
    min-width: 12rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none !important;
}

.navbar-dropdown.show {
    display: flex !important;
}

.navbar-dropdown .dropdown-submenu {
    position: absolute;
    right: 100%;
    top: -7px;
}

.dropdown-parent>.dropdown-submenu a.active {
    color: #ff922a !important;
    font-weight: 700;
}

.navbar-dropdown .menu-items a:hover {
    color: #ff922a !important;
}

.navbar-dropdown .menu-items a:hover:after,
.navbar-dropdown .menu-items a.active:after {
    background-color: transparent !important;
}

.has-search {
    position: relative;
}

.has-search .form-control {
    padding-right: 2.375rem;;
}

.has-search .input-search-btn {
    right: 0.25rem;
    border-radius: 50% !important;
    width: 35px !important;
    height: 35px !important;
    padding: 0 !important;
    vertical-align: middle;
    line-height: 35px;
}

.top-to-btm {
    position: relative;
}

.icon-position {
    position: fixed;
    margin: 0.5rem !important;
    bottom: 80px !important;
    right: 20px !important;
    left: auto;
    top: auto;
    z-index: 1050;
}

.btn.icon-style {
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
    border-radius: 50% !important;
    padding: 0.375rem 0.75rem !important;
    color: white !important;
    height: 50px !important;
    width: 50px !important;
}

.icon-style:hover {
    animation-play-state: paused;
}

@keyframes movebtn {

    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-25px);
    }

    100% {
        transform: translateY(0px);
    }
}
#meganos-toast-container .toast-container-overlay>div {
  margin-bottom: 10px;
  animation: slide-in 0.2s;
  transition: all 0.3s ease;
}

@keyframes slide-in {
  from {
      transform: translateX(100%);
      opacity: 0;
  }

  to {
      transform: translateX(0);
      opacity: 1;
  }
}
